import { Tab, Tabs } from "@mui/material";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { CustomSelect } from "components/common/CustomSelect";
import { Head } from "components/common/Head";
import { Table } from "components/common/Table";
import {
  TABLE_SORTING_TYPES,
  TableColumn
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import { adminRoleSelector } from "modules/auth/selectors";
import * as instancesActions from "modules/instances/actions";
import * as volumesActions from "modules/volumes/actions";
import * as backupsActions from "modules/backups/actions";
import * as pollingActions from "modules/polling/actions";
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { useDebounce } from "hooks/useDebounce";
import { generateSearchString } from "utils/generateSearchString";
import { getSelectOption } from "utils/getSelectOption";
import { appConfig } from "../../appConfig";
import {
  DEFAULT_DEBOUNCE_DELAY,
  DEFAULT_REGION,
  ROUTES,
  VMS_STATUSES_FILTER_OPTIONS
} from "../../constants";
import * as s from "./styles";
import {
  TableOrphanedVirtualMachine,
  TableVirtualMachine
} from "modules/instances/types";
import {
  areInstancesLoadingSelector,
  areOrphanedInstancesCachedLoadingSelector,
  instancesSelector,
  orphanedInstancesCachedSelector,
  tableOrphanedVirtualMachinesCachedSelector,
  tableVirtualMachinesSelector
} from "modules/instances/selectors";
import { cancelVmsTokens } from "modules/instances/sagas";
import { TABS } from "./types";
import { TableVolume } from "modules/volumes/types";
import {
  areErroredVolumesLoadingSelector,
  tableErroredVolumesSelector,
  erroredVolumesSelector,
  orphanedVolumesSelector,
  tableOrphanedVolumesSelector,
  areOrphanedVolumesLoadingSelector
} from "modules/volumes/selectors";
import { cancelVolumesTokens } from "modules/volumes/sagas";
import { TableBackupsSnapshot } from "modules/backups/types";
import {
  areErroredBackupsSnapshotsLoadingSelector,
  cachedBackupsSnapshotsSelector,
  erroredBackupsSnapshotsSelector,
  tableErroredBackupsSnapshotsSelector
} from "modules/backups/selectors";
import { cancelBackupsTokens } from "modules/backups/sagas";

const POLL_ID_PREFIX = "FAILED_RESOURCES";

const POLL_IDS = {
  VMS: "VMs",
  VOLUMES: "VOLUMES",
  BACKUPS: "BACKUPS"
};

export const ORPHANED_RESOURCES_OPTIONS = [
  { value: "vms", label: "Virtual machines" },
  { value: "volumes", label: "Volumes" }
];

const title = "Issues";

const breadcrumbs: Breadcrumb[] = [
  { text: "Issues", url: ROUTES.FAILED_RESOURCES }
];

const tableVMsColumns: TableColumn<TableVirtualMachine>[] = [
  { key: "id", label: "ID" },
  { key: "name", label: "Name" },
  { key: "status", label: "Status" },
  { key: "flavor", label: "Flavor" },
  { key: "ips", label: "IP" },
  { key: "project_name", label: "Project" },
  { key: "organization_id", label: "OrgID" },
  { key: "region", label: "Region" },
  { key: "email", label: "Email" },
  {
    key: "created",
    label: "Created",
    sortingType: TABLE_SORTING_TYPES.DATE
  }
];

const tableVolumesColumns: TableColumn<TableVolume>[] = [
  { key: "id", label: "ID" },
  { key: "name", label: "Name" },
  { key: "status", label: "Status" },
  { key: "sizeString", label: "Size" },
  { key: "project_name", label: "Project" },
  { key: "organization_id", label: "OrgID" },
  { key: "region", label: "Region" },
  { key: "email", label: "Email" },
  {
    key: "created",
    label: "Created",
    sortingType: TABLE_SORTING_TYPES.DATE
  }
];

const tableOrphanedVolumesColumns: TableColumn<TableVolume>[] = [
  { key: "id", label: "ID" },
  { key: "name", label: "Name" },
  { key: "status", label: "Status" },
  { key: "sizeString", label: "Size" },
  { key: "region", label: "Region" },
  {
    key: "created",
    label: "Created",
    sortingType: TABLE_SORTING_TYPES.DATE
  }
];

const tableOrphanedVMsColumns: TableColumn<TableOrphanedVirtualMachine>[] = [
  { key: "id", label: "ID" },
  { key: "name", label: "Name" },
  { key: "status", label: "Status" },
  { key: "flavor", label: "Flavor" },
  { key: "ips", label: "IP" },
  { key: "region", label: "Region" },
  {
    key: "created",
    label: "Created",
    sortingType: TABLE_SORTING_TYPES.DATE
  }
];

const tableBackupsColumns: TableColumn<TableBackupsSnapshot>[] = [
  { key: "id", label: "ID" },
  // { key: "name", label: "Name" },
  { key: "snapshot_type", label: "Type" },
  { key: "status", label: "Status" },
  // { key: "size", label: "Size" },
  // { key: "time_taken", label: "Time taken" },
  // { key: "progress_percent", label: "Progress" },
  { key: "workload_id", label: "Workload" },
  { key: "project_name", label: "Project" },
  { key: "organization_id", label: "OrgID" },
  { key: "region", label: "Region" },
  { key: "email", label: "Email" },
  {
    key: "created_at",
    label: "Created",
    sortingType: TABLE_SORTING_TYPES.DATE
  }
];

export const FailedREsources: FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const adminRole = useSelector(adminRoleSelector);

  const instances = useSelector(instancesSelector);
  const instancesTable = useSelector(tableVirtualMachinesSelector);
  const areInstancesLoading = useSelector(areInstancesLoadingSelector);

  const erroredVolumes = useSelector(erroredVolumesSelector);
  const erroredVolumesTable = useSelector(tableErroredVolumesSelector);
  const areErroredVolumesLoading = useSelector(
    areErroredVolumesLoadingSelector
  );

  const orphanedVolumes = useSelector(orphanedVolumesSelector);
  const orphanedVolumesTable = useSelector(tableOrphanedVolumesSelector);
  const areOrphanedVolumesLoading = useSelector(
    areOrphanedVolumesLoadingSelector
  );

  const orphanedVMsCached = useSelector(orphanedInstancesCachedSelector);
  const orphanedVMsCachedTable = useSelector(
    tableOrphanedVirtualMachinesCachedSelector
  );
  const areOrphanedVMsLoading = useSelector(
    areOrphanedInstancesCachedLoadingSelector
  );

  const erroredBackups = useSelector(erroredBackupsSnapshotsSelector);
  const erroredBackupsTable = useSelector(tableErroredBackupsSnapshotsSelector);
  const areErroredBackupsLoading = useSelector(
    areErroredBackupsSnapshotsLoadingSelector
  );

  const queryParams = new URLSearchParams(location.search);

  const searchParams = queryParams.get("search");
  const [search, setSearch] = useState<string>(
    searchParams ? String(searchParams) : ""
  );
  const debouncedSearch = useDebounce(search, DEFAULT_DEBOUNCE_DELAY);
  const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    // setPage(0);
  }, []);

  const tableRegionsOptions = (
    appConfig.availableRegions
      ? [
          DEFAULT_REGION,
          ...appConfig.availableRegions.map((region) => ({
            name: region,
            id: region
          }))
        ]
      : []
  ).map((region) => getSelectOption(region, "name", "id"));

  const showOnlyRegionParam = queryParams.get("regions");
  const [showOnlyRegion, setShowOnlyRegion] = useState<string>(
    showOnlyRegionParam || tableRegionsOptions[0].value
  );
  const handleChangeRegionFilter = useCallback((value: string) => {
    setShowOnlyRegion(value);
    // setPage(0);
  }, []);

  const showOnlyResourceParam = queryParams.get("resource");
  const [showOnlyResource, setShowOnlyResource] = useState<string>(
    showOnlyResourceParam || ORPHANED_RESOURCES_OPTIONS[0].value
  );
  const handleChangeResourceFilter = useCallback((value: string) => {
    setShowOnlyResource(value);
    // setPage(0);
  }, []);

  const prevShowOnlyRegion = usePrevious(showOnlyRegion);
  const prevShowOnlyResource = usePrevious(showOnlyResource);
  const prevDebounceSearch = usePrevious(debouncedSearch);

  const TAB_TITLES: { [key in TABS]: { title: string; disabled: boolean } } =
    useMemo(
      () => ({
        [TABS.INSTANCES]: {
          title: "Instances",
          disabled: false
        },
        [TABS.VOLUMES]: {
          title: "Volumes",
          disabled: false
        },
        [TABS.BACKUPS]: {
          title: "Backups",
          disabled: false
        },
        [TABS.CLUSTERS]: {
          title: "Clusters",
          disabled: true
        },
        [TABS.ORPHANED_RESOURCES]: {
          title: "Orphaned Resources",
          disabled: false
        }
      }),
      []
    );

  const activeTabIndexFromParam = Object.keys(TAB_TITLES).find(
    (key) =>
      TAB_TITLES[key] &&
      String(TAB_TITLES[key]).toLowerCase() ===
        new URLSearchParams(location.search).get("tab")
  );
  const [activeTabIndex, setActiveTabIndex] = useState(
    Number(activeTabIndexFromParam || TABS.INSTANCES) as TABS
  );
  const previousActiveTabIndex = usePrevious(activeTabIndex);

  const handleChangeTab = useCallback((e, value: number) => {
    setActiveTabIndex(value);
  }, []);

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.VMS}`,
        action: instancesActions.getInstances.started({
          regions: showOnlyRegion,
          status: "ERROR",
          filter: "all"
        })
      })
    );
    history({
      search: generateSearchString({
        tab: TAB_TITLES[TABS.INSTANCES].title,
        regions: String(showOnlyRegion)
      })
    });
  });

  useEffect(() => {
    if (previousActiveTabIndex !== activeTabIndex) {
      setShowOnlyRegion(tableRegionsOptions[0].value);
      setShowOnlyResource(ORPHANED_RESOURCES_OPTIONS[0].value);

      // Cancel the previous requests due to new parameters
      cancelVmsTokens["listVms"]?.cancel("Cancel the previous request");
      cancelVmsTokens["listVms"] = null;

      cancelVolumesTokens["listErroredVolumes"]?.cancel(
        "Cancel the previous request"
      );
      cancelVolumesTokens["listErroredVolumes"] = null;

      cancelBackupsTokens["listErroredBackupsSnapshots"]?.cancel(
        "Cancel the previous request"
      );
      cancelBackupsTokens["listErroredBackupsSnapshots"] = null;

      // stop polling
      Object.values(POLL_IDS).forEach((id) => {
        dispatch(
          pollingActions.stopPolling({
            id: `${POLL_ID_PREFIX}/${id}`
          })
        );
      });

      // clear state - to add loaders if change some of the options
      dispatch(instancesActions.clearInstancesList());
      dispatch(volumesActions.clearErroredVolumesList());
      dispatch(backupsActions.clearErroredBackupsSnapshots());

      switch (activeTabIndex) {
        case TABS.INSTANCES:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.INSTANCES].title,
              regions: showOnlyRegion
            })
          });
          dispatch(
            pollingActions.startPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.VMS}`,
              action: instancesActions.getInstances.started({
                regions: showOnlyRegion,
                status: "ERROR",
                filter: "all"
              })
            })
          );

          break;
        case TABS.VOLUMES:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.VOLUMES].title,
              regions: String(showOnlyRegion)
            })
          });
          dispatch(
            pollingActions.startPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.VOLUMES}`,
              action: volumesActions.getErroredVolumes.started({
                regions: showOnlyRegion
              })
            })
          );

          break;

        case TABS.ORPHANED_RESOURCES:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.ORPHANED_RESOURCES].title,
              // resource: String(showOnlyResource),
              regions: String(showOnlyRegion)
            })
          });
          dispatch(
            instancesActions.getOrphanedInstancesCached.started({
              regions: showOnlyRegion
            })
          );
          dispatch(
            volumesActions.getOrphanedVolumes.started({
              regions: showOnlyRegion
            })
          );
          break;

        case TABS.BACKUPS:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.BACKUPS].title,
              regions: String(showOnlyRegion)
            })
          });
          dispatch(
            backupsActions.getErroredBackupsSnapshots.started({
              regions: showOnlyRegion
            })
          );
          // dispatch(
          //   pollingActions.startPolling({
          //     id: `${POLL_ID_PREFIX}/${POLL_IDS.BACKUPS}`,
          //     action: backupsActions.getErroredBackupsSnapshots.started({
          //       regions: showOnlyRegion
          //     })
          //   })
          // );

          break;

        case TABS.CLUSTERS:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.CLUSTERS].title,
              regions: String(showOnlyRegion)
            })
          });
          break;
      }
    }
  }, [
    previousActiveTabIndex,
    activeTabIndex,
    tableRegionsOptions,
    history,
    TAB_TITLES,
    showOnlyRegion,
    prevShowOnlyRegion,
    dispatch,
    debouncedSearch
  ]);

  useEffect(() => {
    if (
      prevShowOnlyRegion !== undefined &&
      prevShowOnlyRegion !== showOnlyRegion
    ) {
      switch (activeTabIndex) {
        case TABS.INSTANCES:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.INSTANCES].title,
              regions: showOnlyRegion
            })
          });
          // Cancel the previous requests due to new parameters
          cancelVmsTokens["listVms"]?.cancel("Cancel the previous request");
          cancelVmsTokens["listVms"] = null;

          // stop polling
          dispatch(
            pollingActions.stopPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.VMS}`
            })
          );

          // clear state - to add loaders if change some of the options
          dispatch(instancesActions.clearInstancesList());

          dispatch(
            pollingActions.startPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.VMS}`,
              action: instancesActions.getInstances.started({
                regions: showOnlyRegion,
                status: "ERROR"
              })
            })
          );

          break;
        case TABS.VOLUMES:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.VOLUMES].title,
              regions: String(showOnlyRegion)
            })
          });
          // Cancel the previous requests due to new parameters
          cancelVolumesTokens["listErroredVolumes"]?.cancel(
            "Cancel the previous request"
          );
          cancelVolumesTokens["listErroredVolumes"] = null;

          // stop polling
          dispatch(
            pollingActions.stopPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.VOLUMES}`
            })
          );

          // clear state - to add loaders if change some of the options
          dispatch(volumesActions.clearErroredVolumesList());

          dispatch(
            pollingActions.startPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.VOLUMES}`,
              action: volumesActions.getErroredVolumes.started({
                regions: showOnlyRegion
              })
            })
          );
          break;

        case TABS.BACKUPS:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.BACKUPS].title,
              regions: String(showOnlyRegion)
            })
          });
          // Cancel the previous requests due to new parameters
          cancelBackupsTokens["listErroredBackupsSnapshots"]?.cancel(
            "Cancel the previous request"
          );
          cancelBackupsTokens["listErroredBackupsSnapshots"] = null;

          // stop polling
          // dispatch(
          //   pollingActions.stopPolling({
          //     id: `${POLL_ID_PREFIX}/${POLL_IDS.BACKUPS}`
          //   })
          // );

          // clear state - to add loaders if change some of the options
          dispatch(backupsActions.clearErroredBackupsSnapshots());
          dispatch(
            backupsActions.getErroredBackupsSnapshots.started({
              regions: showOnlyRegion
            })
          );
          // dispatch(
          //   pollingActions.startPolling({
          //     id: `${POLL_ID_PREFIX}/${POLL_IDS.BACKUPS}`,
          //     action: backupsActions.getErroredBackupsSnapshots.started({
          //       regions: showOnlyRegion
          //     })
          //   })
          // );
          break;

        case TABS.ORPHANED_RESOURCES:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.ORPHANED_RESOURCES].title,
              // resource: String(showOnlyResource),
              regions: String(showOnlyRegion)
            })
          });
          dispatch(
            instancesActions.getOrphanedInstancesCached.started({
              regions: showOnlyRegion
            })
          );
          dispatch(
            volumesActions.getOrphanedVolumes.started({
              regions: showOnlyRegion
            })
          );

          break;
      }
    }
  }, [
    previousActiveTabIndex,
    activeTabIndex,
    tableRegionsOptions,
    history,
    TAB_TITLES,
    showOnlyRegion,
    prevShowOnlyRegion,
    dispatch,
    prevDebounceSearch,
    debouncedSearch,
    prevShowOnlyResource,
    showOnlyResource
  ]);

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(instancesActions.clear());
    dispatch(volumesActions.clear());
    dispatch(backupsActions.clear());
  });

  const generateVMsTableItemURL = useCallback(
    (id: string) =>
      generatePath(ROUTES.USER, {
        userId: instancesTable?.find((vm) => vm.id == id)?.user_id
      }),
    [instancesTable]
  );

  const generateVolumesTableItemURL = useCallback(
    (id: string) =>
      generatePath(ROUTES.USER, {
        userId: erroredVolumesTable?.find((v) => v.id == id)?.user_id
      }),
    [erroredVolumesTable]
  );

  const generateOrphanedVolumesTableItemURL = useCallback(
    (id: string) =>
      generatePath(ROUTES.USER, {
        userId: orphanedVolumesTable?.find((v) => v.id == id)?.user_id
      }),
    [orphanedVolumesTable]
  );

  const generateOrphanedVMsTableItemURL = useCallback(
    (id: string) =>
      generatePath(ROUTES.USER, {
        userId: orphanedVMsCachedTable?.find((v) => v.id == id)?.user_id
      }),
    [orphanedVMsCachedTable]
  );

  const generateBackupsTableItemURL = useCallback(
    (id: string) =>
      generatePath(ROUTES.USER, {
        userId: erroredBackupsTable?.find((v) => v.id == id)?.user_id
      }),
    [erroredBackupsTable]
  );

  const tabContent = [
    <>
      <Table<TableVirtualMachine>
        isSearchEnabled={true}
        isSortingEnabled={true}
        rows={instancesTable || []}
        columns={tableVMsColumns}
        isLoading={!instancesTable}
        itemLink={{
          column: "email",
          getURL: generateVMsTableItemURL
        }}
        itemWithIcons={[
          {
            withText: false,
            column: "status",
            getIcon: (row) => {
              return (
                <s.TypographyColoredDanger>
                  {row["status"]}
                </s.TypographyColoredDanger>
              );
              // return (
              //   <s.CustomTableCellText>{row["status"]}</s.CustomTableCellText>
              // ); // or default icon
            },
            tooltipText: (row) => {
              return `Error: ${String(row.faultMessage)}`;
            }
          }
        ]}
        // customItem={
        //   <s.CustomDiv>
        //     <CustomSelect
        //       label={"Region"}
        //       options={tableRegionsOptions}
        //       defaultValue={tableRegionsOptions[0].value}
        //       onChange={handleChangeRegionFilter}
        //     />
        //   </s.CustomDiv>
        // }
        toolbarItems={
          <>
            <CustomSelect
              label={"Region"}
              options={tableRegionsOptions}
              defaultValue={tableRegionsOptions[0].value}
              value={showOnlyRegion}
              onChange={handleChangeRegionFilter}
              margin={"0 15px 0 0"}
            />
          </>
        }
      />
    </>,
    <>
      <Table<TableVolume>
        isSearchEnabled={true}
        isSortingEnabled={true}
        rows={erroredVolumesTable || []}
        columns={tableVolumesColumns}
        isLoading={!erroredVolumesTable}
        itemLink={{
          column: "email",
          getURL: generateVolumesTableItemURL
        }}
        itemWithIcons={[
          {
            withText: false,
            column: "status",
            getIcon: (row) => {
              return (
                <s.TypographyColoredDanger>
                  {row["status"]}
                </s.TypographyColoredDanger>
              );
            },
            tooltipText: (row) => {
              return ``;
            }
          }
        ]}
        toolbarItems={
          <>
            <CustomSelect
              label={"Region"}
              options={tableRegionsOptions}
              defaultValue={tableRegionsOptions[0].value}
              value={showOnlyRegion}
              onChange={handleChangeRegionFilter}
              margin={"0 15px 0 0"}
            />
          </>
        }
      />
    </>,
    <>
      <Table<TableBackupsSnapshot>
        isSearchEnabled={true}
        isSortingEnabled={true}
        rows={erroredBackupsTable || []}
        columns={tableBackupsColumns}
        isLoading={!erroredBackupsTable}
        itemLink={{
          column: "email",
          getURL: generateBackupsTableItemURL
        }}
        itemWithIcons={[
          {
            withText: false,
            column: "status",
            getIcon: (row) => {
              return (
                <s.TypographyColoredDanger>
                  {row["status"]}
                </s.TypographyColoredDanger>
              );
            },
            tooltipText: (row) => {
              return `${String(row.msg)}`;
            }
          }
        ]}
        toolbarItems={
          <>
            <CustomSelect
              label={"Region"}
              options={tableRegionsOptions}
              defaultValue={tableRegionsOptions[0].value}
              value={showOnlyRegion}
              onChange={handleChangeRegionFilter}
              margin={"0 15px 0 0"}
            />
          </>
        }
      />
    </>,

    <></>,
    <>
      {showOnlyResource === "volumes" ? (
        <Table<TableVolume>
          isSearchEnabled={true}
          isSortingEnabled={true}
          rows={orphanedVolumesTable || []}
          columns={tableOrphanedVolumesColumns}
          isLoading={areOrphanedVolumesLoading}
          itemLink={{
            column: "email",
            getURL: generateOrphanedVolumesTableItemURL
          }}
          toolbarItems={
            <>
              <CustomSelect
                label={"Resource"}
                options={ORPHANED_RESOURCES_OPTIONS}
                defaultValue={ORPHANED_RESOURCES_OPTIONS[0].value}
                value={showOnlyResource}
                onChange={handleChangeResourceFilter}
                margin={"0 15px 0 0"}
              />
              <CustomSelect
                label={"Region"}
                options={tableRegionsOptions}
                defaultValue={tableRegionsOptions[0].value}
                value={showOnlyRegion}
                onChange={handleChangeRegionFilter}
                margin={"0 15px 0 0"}
              />
            </>
          }
          itemWithIcons={[
            {
              withText: false,
              column: "status",
              getIcon: (row) => {
                if (row["status"] === "available") {
                  return (
                    <s.TypographyColoredSuccess>
                      {row["status"]}
                    </s.TypographyColoredSuccess>
                  );
                }
                if (row["status"] === "reserved") {
                  return (
                    <s.TypographyColoredPrimary>
                      {row["status"]}
                    </s.TypographyColoredPrimary>
                  );
                }
                if (row["status"].includes("error")) {
                  return (
                    <s.TypographyColoredDanger>
                      {row["status"]}
                    </s.TypographyColoredDanger>
                  );
                }
                return (
                  <s.CustomTableCellText>{row["status"]}</s.CustomTableCellText>
                );
              },
              tooltipText: (row) => {
                return ``;
              }
            }
          ]}
        />
      ) : (
        <Table<TableOrphanedVirtualMachine>
          isSearchEnabled={true}
          isSortingEnabled={true}
          rows={orphanedVMsCachedTable || []}
          columns={tableOrphanedVMsColumns}
          isLoading={areOrphanedVMsLoading}
          itemLink={{
            column: "email",
            getURL: generateOrphanedVMsTableItemURL
          }}
          toolbarItems={
            <>
              <CustomSelect
                label={"Resource"}
                options={ORPHANED_RESOURCES_OPTIONS}
                defaultValue={ORPHANED_RESOURCES_OPTIONS[0].value}
                value={showOnlyResource}
                onChange={handleChangeResourceFilter}
                margin={"0 15px 0 0"}
              />
              <CustomSelect
                label={"Region"}
                options={tableRegionsOptions}
                defaultValue={tableRegionsOptions[0].value}
                value={showOnlyRegion}
                onChange={handleChangeRegionFilter}
                margin={"0 15px 0 0"}
              />
            </>
          }
        />
      )}
    </>
  ];

  return (
    <>
      <Head title={title} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <s.SummaryContainer>
        <s.Title variant={"h4"} component={"h2"}>
          {title}
        </s.Title>
      </s.SummaryContainer>
      <s.TabsContainer>
        <Tabs value={activeTabIndex} onChange={handleChangeTab}>
          {Object.values(TAB_TITLES).map((tab) => (
            <Tab key={tab.title} label={tab.title} disabled={tab.disabled} />
          ))}
        </Tabs>
      </s.TabsContainer>
      {tabContent[activeTabIndex]}
    </>
  );
};
