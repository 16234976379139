import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import MuiListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { appConfig } from "../../appConfig";

export const DRAWER_WIDTH = 240; // in pixels

export const Container = styled.div`
  display: flex;
  min-height: 100%;
`;

export const DrawerHeader = styled.div`
  margin-bottom: 0;
  background-color: ${({ theme }) =>
    appConfig.theme.logoBackgroundColor || theme.palette.background.default};
`;

export const LogoContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ theme }) => theme.spacing(10)};
  margin-bottom: 0;
  /* margin-bottom: ${({ theme }) => theme.spacing(2)}; */
`;

export const LogoImage = styled.img`
  max-height: 40px;
  max-width: 224px;
`;

export const Drawer = styled(MuiDrawer)`
  display: flex;
  flex-shrink: 0;

  & .MuiDrawer-paper {
    width: ${DRAWER_WIDTH}px;
    overflow: hidden;
  }
`;

export const DrawerMenuList = styled(List)`
  overflow-y: auto;
  padding: ${({ theme }) => theme.spacing(0)};
`;

export const IconContainer = styled.span`
  display: flex;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const ListItem = styled.li`
  padding: 0;
`;

export const Link = styled(RouterLink)`
  padding: ${({ theme }) => theme.spacing(1, 2)};
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  color: ${({ theme }) => theme.palette.text.primary};
  width: 100%;
`;

export const SectionName = styled(MuiListItem)`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  background-color: ${({ theme }) => theme.palette.secondary.main};
  min-height: ${({ theme }) => theme.spacing(5)};
`;

export const AdminSectionHeader = styled(MuiListItem)`
  margin-top: ${({ theme }) => theme.spacing(-1)};
  padding-top: ${({ theme }) => theme.spacing(1.1)};
  justify-content: center;
  min-height: ${({ theme }) => theme.spacing(5)};
  font-variant: small-caps;
  color: ${({ theme }) => theme.palette.primary.light};
  background-color: ${({ theme }) =>
    (appConfig.theme.logoBackgroundColor === "#002060"
      ? theme.palette.background.default
      : appConfig.theme.logoBackgroundColor) ||
    theme.palette.background
      .paper}; /* processing of the blue-cloud case so that it is white and not the logo color */
`;

export const SectionNameText = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.9rem;
`;

export const MenuFooter = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(15)};
`;
