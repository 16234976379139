import { createSelector } from "reselect";
import { formatDate } from "utils/formatDate";
import { formatSize } from "utils/formatSize";
import { DATE_FORMATS } from "../../constants";
import { RootReducer } from "../../reducers";
import { backupSnapshots, cachedBackups } from "./drafts";
import {
  BackupsSnapshots,
  BackupsSnapshotsCached,
  BackupsSnapshotsOverviewData,
  TableBackupsSnapshot
} from "./types";
import { formatTime2 } from "utils/formatTime";

// errored backups in real TIME
export const areErroredBackupsSnapshotsLoadingSelector = (
  state: RootReducer
): boolean => state.backups.areErroredBackupsSnapshotsLoading;

export const erroredBackupsSnapshotsSelector = (
  state: RootReducer
): BackupsSnapshots | null => state.backups.erroredBackupsSnapshots;
// ): BackupsSnapshots | null => backupSnapshots;

export const tableErroredBackupsSnapshotsSelector = createSelector(
  [erroredBackupsSnapshotsSelector],
  (erroredBackupsSnapshots): TableBackupsSnapshot[] | null =>
    erroredBackupsSnapshots
      ? Object.values(erroredBackupsSnapshots).flatMap((region) =>
          region.snapshots.map((snapshot) => ({
            id: snapshot.id,
            name: snapshot.name,
            status: snapshot.status.toUpperCase(),
            snapshot_type: snapshot.snapshot_type,
            size: snapshot.size ? `${formatSize(snapshot.size)}` : "0",
            restore_size: snapshot.restore_size
              ? `${formatSize(snapshot.restore_size)}`
              : "0",
            uploaded_size: snapshot.restore_size
              ? `${formatSize(snapshot.uploaded_size)}`
              : "0",
            progress_percent: `${snapshot.progress_percent} %`,
            time_taken: snapshot.time_taken
              ? `${formatTime2(snapshot.time_taken)}`
              : "0",
            progress_msg: snapshot.progress_msg,
            warning_msg: snapshot.warning_msg,
            error_msg: snapshot.error_msg,
            msg:
              [
                snapshot.progress_msg
                  ? `PROGRESS: ${snapshot.progress_msg}`
                  : null,
                snapshot.warning_msg
                  ? `WARNING: ${snapshot.warning_msg}`
                  : null,
                snapshot.error_msg ? `ERROR: ${snapshot.error_msg}` : null
              ]
                .filter(Boolean)
                .join(", ") || "No description",
            workload_id: snapshot.workload_id,
            project_id: snapshot.project_id,
            project_name: snapshot.project_name,
            user_id: snapshot.user_id,
            user_name: snapshot.user_name,
            organization_id: snapshot.organization_id,
            email: snapshot.email,
            region: snapshot.region,
            created_at: `${formatDate(
              new Date(snapshot.created_at),
              DATE_FORMATS.DATETIME
            )} UTC`
          }))
        )
      : null
);

// all cached backups
export const areCachedBackupsSnapshotsLoadingSelector = (
  state: RootReducer
): boolean => state.backups.areCachedBackupsSnapshotsLoading;

export const cachedBackupsSnapshotsSelector = (
  state: RootReducer
): BackupsSnapshotsCached | null => state.backups.cachedBackupsSnapshots;
// ): BackupsSnapshotsCached | null => cachedBackups;

export const tableCachedBackupsSnapshotsSelector = createSelector(
  [cachedBackupsSnapshotsSelector],
  (cachedBackupsSnapshots): TableBackupsSnapshot[] | null =>
    cachedBackupsSnapshots && cachedBackupsSnapshots.snapshots_by_region
      ? Object.values(cachedBackupsSnapshots.snapshots_by_region).flatMap(
          (region) =>
            Array.isArray(region.snapshots)
              ? region.snapshots.map((snapshot) => ({
                  id: snapshot.id,
                  name: snapshot.name,
                  status: snapshot.status.toUpperCase(),
                  snapshot_type: snapshot.snapshot_type,
                  size: snapshot.size ? `${formatSize(snapshot.size)}` : "0",
                  restore_size: snapshot.restore_size
                    ? `${formatSize(snapshot.restore_size)}`
                    : "0",
                  uploaded_size: snapshot.restore_size
                    ? `${formatSize(snapshot.uploaded_size)}`
                    : "0",
                  progress_percent: `${snapshot.progress_percent} %`,
                  time_taken: snapshot.time_taken
                    ? `${formatTime2(snapshot.time_taken)}`
                    : "0",
                  progress_msg: snapshot.progress_msg,
                  warning_msg: snapshot.warning_msg,
                  error_msg: snapshot.error_msg,
                  msg:
                    [
                      snapshot.progress_msg
                        ? `PROGRESS: ${snapshot.progress_msg}`
                        : null,
                      snapshot.warning_msg
                        ? `WARNING: ${snapshot.warning_msg}`
                        : null,
                      snapshot.error_msg ? `ERROR: ${snapshot.error_msg}` : null
                    ]
                      .filter(Boolean)
                      .join(", ") || "No description",
                  workload_id: snapshot.workload_id,
                  project_id: snapshot.project_id,
                  project_name: snapshot.project_name,
                  user_id: snapshot.user_id,
                  user_name: snapshot.user_name,
                  organization_id: snapshot.organization_id,
                  email: snapshot.email,
                  region: snapshot.region,
                  created_at: `${formatDate(
                    new Date(snapshot.created_at),
                    DATE_FORMATS.DATETIME
                  )} UTC`
                }))
              : []
        )
      : null
);

export const cachedBackupsSnapshotsOverviewSelector = createSelector(
  [cachedBackupsSnapshotsSelector],
  (cachedBackupsSnapshots): BackupsSnapshotsOverviewData[] | null =>
    cachedBackupsSnapshots
      ? Object.entries(cachedBackupsSnapshots.snapshots_by_region).map(
          ([region, data]) => ({
            region,
            total_snapshots_size: data.total_snapshots_size
              ? `${formatSize(data.total_snapshots_size)}`
              : "0",
            total_snapshots_count: data.total_snapshots_count,
            total_servers_count: data.total_servers_count || 0,
            protected_servers_count: data.protected_servers_count || 0,
            total_full_snapshots_count: data.statistics_by_type.full || 0,
            total_incremental_snapshots_count:
              data.statistics_by_type.incremental || 0,
            statistics_by_statuses: data.statistics_by_statuses,
            statistics_by_type: data.statistics_by_type
          })
        )
      : null
);

export const backupsSnapshotsCacheDateSelector = createSelector(
  [cachedBackupsSnapshotsSelector],
  (cachedBackupsSnapshots): string | null =>
    cachedBackupsSnapshots?.cached_at
      ? formatDate(
          new Date(cachedBackupsSnapshots.cached_at),
          DATE_FORMATS.DATETIME
        )
      : null
);

export const isBAckupsSnapshotsCacheUpdatingSelector = (
  state: RootReducer
): boolean => state.backups.isCacheUpdating;
