import axios, { AxiosResponse, CancelTokenSource } from "axios";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { isPollStoppedSaga } from "modules/polling/sagas";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { getAxiosErrorMessage } from "utils/getAxiosErrorMessage";
import { axiosInstance } from "../../axios";
import * as actions from "./actions";
import {
  GetAllBackupsSnapshotsParams,
  GetAllBackupsSnapshotsResponse,
  GetAllCachedBackupsSnapshotsParams,
  GetAllCachedBackupsSnapshotsResponse,
  GetErroredBackupsSnapshotsParams,
  GetErroredBackupsSnapshotsResponse,
  UpdateCacheBackupsSnapshotsResponse
} from "./types";

export const cancelBackupsTokens: {
  [key: string]: CancelTokenSource | undefined | null;
} = {};

// snapshots all real time
export function* getAllBackupsSnapshotsSaga(
  action: Action<GetAllBackupsSnapshotsParams>
): SagaIterator<void> {
  try {
    if (!cancelBackupsTokens["listBackupsSnapshots"]) {
      cancelBackupsTokens["listBackupsSnapshots"] = axios.CancelToken.source();
    }

    const { regions, status } = action.payload;
    const response: AxiosResponse<GetAllBackupsSnapshotsResponse> = yield call(
      axiosInstance.get,
      `gotham-governor/method/admin/snapshots`,
      {
        params: {
          regions: regions ? regions : "",
          statuses: status ? status : ""
        },
        cancelToken: cancelBackupsTokens["listBackupsSnapshots"].token
      }
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getAllBackupsSnapshots.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(
        actions.getAllBackupsSnapshots.failed({
          params: action.payload,
          error: e
        })
      );
      yield put(
        notificationsActions.showNotification({
          title: "Failed to get backups snapshots data",
          text: getAxiosErrorMessage(e),
          type: NOTIFICATION_TYPES.ERROR
        })
      );
    }
  }
}

// snapshots errored
export function* getErroredBackupsSnapshotsSaga(
  action: Action<GetErroredBackupsSnapshotsParams>
): SagaIterator<void> {
  try {
    if (!cancelBackupsTokens["listErroredBackupsSnapshots"]) {
      cancelBackupsTokens["listErroredBackupsSnapshots"] =
        axios.CancelToken.source();
    }

    const { regions } = action.payload;
    const response: AxiosResponse<GetErroredBackupsSnapshotsResponse> =
      yield call(axiosInstance.get, `gotham-governor/method/admin/snapshots`, {
        params: {
          regions: regions ? regions : "",
          statuses: "error"
        },
        cancelToken: cancelBackupsTokens["listErroredBackupsSnapshots"].token
      });
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getErroredBackupsSnapshots.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(
        actions.getErroredBackupsSnapshots.failed({
          params: action.payload,
          error: e
        })
      );
      yield put(
        notificationsActions.showNotification({
          title: "Failed to get backups errored snapshots data",
          text: getAxiosErrorMessage(e),
          type: NOTIFICATION_TYPES.ERROR
        })
      );
    }
  }
}

// snapshots all cashed
export function* getAllCachedBackupsSnapshotsSaga(
  action: Action<GetAllCachedBackupsSnapshotsParams>
): SagaIterator<void> {
  try {
    if (!cancelBackupsTokens["listAllCachedBackupsSnapshots"]) {
      cancelBackupsTokens["listAllCachedBackupsSnapshots"] =
        axios.CancelToken.source();
    }

    const { regions, status } = action.payload;
    const response: AxiosResponse<GetAllCachedBackupsSnapshotsResponse> =
      yield call(
        axiosInstance.get,
        `gotham-governor/method/admin/snapshots/overview`,
        {
          params: {
            regions: regions ? regions : "",
            statuses: status ? status : ""
          },
          cancelToken:
            cancelBackupsTokens["listAllCachedBackupsSnapshots"].token
        }
      );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getAllCachedBackupsSnapshots.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(
        actions.getAllCachedBackupsSnapshots.failed({
          params: action.payload,
          error: e
        })
      );
      yield put(
        notificationsActions.showNotification({
          title: "Failed to get cached backups snapshots data",
          text: getAxiosErrorMessage(e),
          type: NOTIFICATION_TYPES.ERROR
        })
      );
    }
  }
}

export function* updateCacheBackupsSnapshotsSaga(
  action: Action<void>
): SagaIterator<void> {
  try {
    const response: AxiosResponse<UpdateCacheBackupsSnapshotsResponse> =
      yield call(
        axiosInstance.get,
        `gotham-governor/method/admin/snapshots/force-cache-update`
      );

    yield put(
      actions.updateCacheBackupsSnapshots.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title:
          "Backups report for all regions requested. Expect it within a few minutes.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.updateCacheBackupsSnapshots.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update cached backups data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeEvery(
      actions.getAllBackupsSnapshots.started,
      getAllBackupsSnapshotsSaga
    ),
    takeEvery(
      actions.getErroredBackupsSnapshots.started,
      getErroredBackupsSnapshotsSaga
    ),
    takeEvery(
      actions.getAllCachedBackupsSnapshots.started,
      getAllCachedBackupsSnapshotsSaga
    ),
    takeEvery(
      actions.updateCacheBackupsSnapshots.started,
      updateCacheBackupsSnapshotsSaga
    )
  ]);
}
