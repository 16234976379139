import { FC, useState } from "react";
import { PieChartMetricProps } from "./types";
import { pieArcLabelClasses, PieChart } from "@mui/x-charts";
import * as s from "./styles";
import { Box, List, ListItem, Popover, Typography } from "@mui/material";

export const PieChartMetric: FC<PieChartMetricProps> = ({
  title,
  dataChart,
  total,
  unit,
  simpleMetrics,
  showTotalString = true,
  showUnitInLegend = true,
  formatter,
  width,
  sortDescending,
  maxItemsInLegend,
  showPopoverOnOtherClick
}: PieChartMetricProps) => {
  const [anchorEl, setAnchorEl] = useState<SVGRectElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<SVGRectElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => setAnchorEl(null);
  const isPopoverOpen = Boolean(anchorEl);

  // sort data if sortDescending is defined
  const sortedDataChart = sortDescending
    ? [...dataChart].sort((a, b) => b.value - a.value)
    : dataChart;

  // slice data and recalculate total if maxItemsInLegend is defined
  const slicedDataChart = maxItemsInLegend
    ? sortedDataChart.slice(0, maxItemsInLegend)
    : sortedDataChart;
  const slicedTotal = maxItemsInLegend
    ? slicedDataChart.reduce((sum, item) => sum + item.value, 0)
    : total;

  const otherTotal = total - slicedTotal;

  const data = slicedDataChart.map((item, index) => {
    const formatted = formatter
      ? formatter(item.value)
      : { value: item.value, unit };

    return {
      id: index + 1,
      value: Number(((item.value / total) * 100).toFixed(0)), // %
      label: `${item.label}: ${formatted.value}${showUnitInLegend ? ` ${formatted.unit}` : ""}`,
      ...(item.color ? { color: item.color } : {})
    };
  });

  if (maxItemsInLegend && dataChart.length > maxItemsInLegend) {
    data.push({
      id: data.length + 1,
      value: Number(((otherTotal / total) * 100).toFixed(0)), // %
      label: `other: ${otherTotal}${showUnitInLegend ? ` ${unit}` : ""}`,
      color: "transparent"
    });
  }

  const formattedTotal = formatter ? formatter(total) : { value: total, unit };

  return (
    <s.MetricWrapper>
      <s.ChartContainer>
        <s.MetricTitleContainer>
          <s.MetricTitle>{title}</s.MetricTitle>
        </s.MetricTitleContainer>
        <s.MetricTitleContainer>
          {showTotalString && (
            <s.DetailsInfoColored>
              Total: {formattedTotal.value} {formattedTotal.unit}
            </s.DetailsInfoColored>
          )}
        </s.MetricTitleContainer>
        <PieChart
          series={[
            {
              data,
              innerRadius: 30,
              outerRadius: 80,
              paddingAngle: 5,
              cornerRadius: 5,
              startAngle: -90,
              endAngle: 360,
              cx: 90,
              cy: 90,
              arcLabel: (item) => `${item.value} %`,
              valueFormatter: (value) => `${value.value} %`,
              arcLabelMinAngle: 45,
              highlightScope: { faded: "global", highlighted: "item" },
              highlighted: {
                additionalRadius: 1
                // innerRadius: 41
                // color: theme.palette.secondary.dark
              }
            }
          ]}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: "white",
              fontWeight: "bold"
            }
          }}
          slotProps={{
            legend: {
              direction: "column",
              position: { vertical: "middle", horizontal: "right" },
              padding: 0,
              markGap: 6,
              itemGap: 8,
              itemMarkHeight: 14,
              labelStyle: {
                fontSize: 13,
                fontWeight: 430,
                width: width ? "125px" : "initial"
              },
              onItemClick: (
                event: React.MouseEvent<SVGRectElement>,
                legendItem,
                index
              ) => {
                if (
                  showPopoverOnOtherClick &&
                  legendItem &&
                  legendItem.label.startsWith("other")
                ) {
                  handlePopoverOpen(event);
                }
              }
            }
          }}
          // tooltip={{
          //   trigger: "none",
          //   itemContent: (props) => <div {...props}>{unit}</div>
          // }}
          width={width ? width : 325}
          height={200}
        />
      </s.ChartContainer>

      <Popover
        open={isPopoverOpen}
        closeAfterTransition={false}
        anchorEl={anchorEl}
        disableAutoFocus
        disableEnforceFocus
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <Box sx={{ py: 1, px: 2 }}>
          <Typography variant="subtitle2">Full Statistic ({unit})</Typography>
          <List>
            {sortedDataChart.map((item) => (
              <ListItem key={item.label} sx={{ p: 0.2, fontSize: 13 }}>
                {item.label}:{" "}
                <Typography
                  component="span"
                  sx={{ fontWeight: "450", fontSize: 13, ml: 1 }}
                >
                  {formatter ? formatter(item.value).value : item.value}
                  {showUnitInLegend ? ` ${unit}` : ""}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>

      {simpleMetrics.length > 0 && (
        <s.SimpleMetricsContainer>
          {simpleMetrics.map((metric) => (
            <s.SimpleMetricItem key={metric.label} width={metric.width}>
              <s.SimpleMetricTitle>{metric.label}</s.SimpleMetricTitle>
              <s.SimpleMetricValue>
                {metric.value} {metric.unit}
              </s.SimpleMetricValue>
            </s.SimpleMetricItem>
          ))}
        </s.SimpleMetricsContainer>
      )}
    </s.MetricWrapper>
  );
};
