import actionCreatorFactory from "typescript-fsa";
import {
  GetAllBackupsSnapshotsParams,
  GetAllBackupsSnapshotsResponse,
  GetAllCachedBackupsSnapshotsParams,
  GetAllCachedBackupsSnapshotsResponse,
  GetErroredBackupsSnapshotsParams,
  GetErroredBackupsSnapshotsResponse,
  UpdateCacheBackupsSnapshotsResponse
} from "./types";

const actionCreator = actionCreatorFactory("BACKUPS");

export const getAllCachedBackupsSnapshots = actionCreator.async<
  GetAllCachedBackupsSnapshotsParams,
  GetAllCachedBackupsSnapshotsResponse,
  unknown
>("GET_ALL_CACHED_BACKUPS_SNAPSHOTS");

export const getAllBackupsSnapshots = actionCreator.async<
  GetAllBackupsSnapshotsParams,
  GetAllBackupsSnapshotsResponse,
  unknown
>("GET_ALL_BACKUPS_SNAPSHOTS");

export const getErroredBackupsSnapshots = actionCreator.async<
  GetErroredBackupsSnapshotsParams,
  GetErroredBackupsSnapshotsResponse,
  unknown
>("GET_ERRORED_BACKUPS_SNAPSHOTS");

export const updateCacheBackupsSnapshots = actionCreator.async<
  void,
  UpdateCacheBackupsSnapshotsResponse,
  unknown
>("UPDATE_CACHE_BACKUPS_SNAPSHOTS");

//clear

export const clearErroredBackupsSnapshots = actionCreator<void>(
  "CLEAR_ERRORED_BACKUPS_SNAPSHOTS"
);
export const clearCachedBackupsSnapshots = actionCreator<void>(
  "CLEAR_CACHED_BACKUPS_SNAPSHOTS"
);
export const clear = actionCreator<void>("CLEAR");
