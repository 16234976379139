import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import { BackupsSnapshots, BackupsSnapshotsCached } from "./types";

export type Reducer = {
  backupsSnapshots: BackupsSnapshots | null;
  areBackupsSnapshotsLoading: boolean;
  erroredBackupsSnapshots: BackupsSnapshots | null;
  areErroredBackupsSnapshotsLoading: boolean;
  cachedBackupsSnapshots: BackupsSnapshotsCached | null;
  areCachedBackupsSnapshotsLoading: boolean;
  isCacheUpdating: boolean;
};

const initialState: Reducer = {
  backupsSnapshots: null,
  areBackupsSnapshotsLoading: false,
  erroredBackupsSnapshots: null,
  areErroredBackupsSnapshotsLoading: false,
  cachedBackupsSnapshots: null,
  areCachedBackupsSnapshotsLoading: false,
  isCacheUpdating: false
};

export const reducer = reducerWithInitialState(initialState)
  //get all snapshots
  .case(
    actions.getAllBackupsSnapshots.started,
    (state): Reducer => ({
      ...state,
      areBackupsSnapshotsLoading: true
    })
  )
  .case(
    actions.getAllBackupsSnapshots.done,
    (state, payload): Reducer => ({
      ...state,
      backupsSnapshots: payload.result,
      areBackupsSnapshotsLoading: false
    })
  )
  .case(
    actions.getAllBackupsSnapshots.failed,
    (state): Reducer => ({
      ...state,
      areBackupsSnapshotsLoading: false
    })
  )

  //get errored snapshots (all same)
  .case(
    actions.getErroredBackupsSnapshots.started,
    (state): Reducer => ({
      ...state,
      areErroredBackupsSnapshotsLoading: true
    })
  )
  .case(
    actions.getErroredBackupsSnapshots.done,
    (state, payload): Reducer => ({
      ...state,
      erroredBackupsSnapshots: payload.result,
      areErroredBackupsSnapshotsLoading: false
    })
  )
  .case(
    actions.getErroredBackupsSnapshots.failed,
    (state): Reducer => ({
      ...state,
      areErroredBackupsSnapshotsLoading: false
    })
  )

  //get all cached snapshots (all same)
  .case(
    actions.getAllCachedBackupsSnapshots.started,
    (state): Reducer => ({
      ...state,
      areCachedBackupsSnapshotsLoading: true
    })
  )
  .case(
    actions.getAllCachedBackupsSnapshots.done,
    (state, payload): Reducer => ({
      ...state,
      cachedBackupsSnapshots: payload.result,
      areCachedBackupsSnapshotsLoading: false
    })
  )
  .case(
    actions.getAllCachedBackupsSnapshots.failed,
    (state): Reducer => ({
      ...state,
      areCachedBackupsSnapshotsLoading: false
    })
  )

  // Update cache
  .case(
    actions.updateCacheBackupsSnapshots.started,
    (state): Reducer => ({
      ...state,
      isCacheUpdating: true
    })
  )
  .case(
    actions.updateCacheBackupsSnapshots.done,
    (state, payload): Reducer => ({
      ...state,
      isCacheUpdating: false
    })
  )
  .case(
    actions.updateCacheBackupsSnapshots.failed,
    (state): Reducer => ({
      ...state,
      isCacheUpdating: false
    })
  )

  //clear
  .case(
    actions.clearErroredBackupsSnapshots,
    (state): Reducer => ({
      ...state,
      erroredBackupsSnapshots: initialState.erroredBackupsSnapshots
    })
  )
  .case(
    actions.clearCachedBackupsSnapshots,
    (state): Reducer => ({
      ...state,
      cachedBackupsSnapshots: initialState.cachedBackupsSnapshots
    })
  )

  .case(actions.clear, (): Reducer => initialState);
