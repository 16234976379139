import {
  Box,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  useTheme,
  Zoom
} from "@mui/material";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { CustomSelect } from "components/common/CustomSelect";
import { Head } from "components/common/Head";
import { Loader } from "components/common/Loader";
import { PieChartMetric } from "components/common/PieChartMetric";
import { Table } from "components/common/Table";
import {
  TABLE_SORTING_TYPES,
  TableColumn
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import { adminRoleSelector } from "modules/auth/selectors";
import * as instancesActions from "modules/instances/actions";
import { cancelVmsTokens } from "modules/instances/sagas";
import {
  areGpuInstancesLoadingSelector,
  areInstancesCachedLoadingSelector,
  gpuInstancesSelector,
  instancesCachedSelector,
  instancesCashDateSelector,
  instancesOverviewSelector,
  isInstancesCacheUpdatingSelector,
  tableGpuVirtualMachinesSelector,
  tableVirtualMachinesCachedSelector
} from "modules/instances/selectors";
import {
  INSTANCE_FLAVORS,
  INSTANCE_STATUSES,
  TableVirtualMachine
} from "modules/instances/types";
import * as pollingActions from "modules/polling/actions";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { generateSearchString } from "utils/generateSearchString";
import { getSelectOption } from "utils/getSelectOption";
import { appConfig } from "../../appConfig";
import {
  DEFAULT_REGION,
  ROUTES,
  VMS_STATUSES_FILTER_OPTIONS
} from "../../constants";
import * as s from "./styles";
import { TABS } from "./types";

const POLL_ID_PREFIX = "Instances";

const POLL_IDS = {
  allInstances: "All_Instances",
  gpuInstances: "GPU_Instances"
};

const title = "Virtual Machines";

const breadcrumbs: Breadcrumb[] = [
  { text: "Virtual Machines", url: ROUTES.VIRTUAL_MACHINES }
];

const tableColumns: TableColumn<TableVirtualMachine>[] = [
  { key: "id", label: "ID" },
  { key: "name", label: "Name" },
  { key: "flavor", label: "Flavor" },
  { key: "status", label: "Status" },
  { key: "ips", label: "IP" },
  { key: "project_name", label: "Project" },
  { key: "organization_id", label: "OrgID" },
  { key: "region", label: "Region" },
  { key: "email", label: "Email" },
  {
    key: "created",
    label: "Created",
    sortingType: TABLE_SORTING_TYPES.DATE
  }
];

export const VirtualMachines: FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const history = useNavigate();
  const adminRole = useSelector(adminRoleSelector);

  const gpuInstances = useSelector(gpuInstancesSelector);
  const gpuInstancesTable = useSelector(tableGpuVirtualMachinesSelector);
  const areGpuInstancesLoading = useSelector(areGpuInstancesLoadingSelector);

  const instancesCached = useSelector(instancesCachedSelector);
  const cashedInstancesTable = useSelector(tableVirtualMachinesCachedSelector);
  const instancesCachedDate = useSelector(instancesCashDateSelector);
  const prevInstancesCachedDate = usePrevious(instancesCachedDate);
  const areInstancesCachedLoading = useSelector(
    areInstancesCachedLoadingSelector
  );
  const [isRefreshButtonDisabled, setIsRefreshButtonDisabled] = useState(false);

  const isInstancesCacheUpdating = useSelector(
    isInstancesCacheUpdatingSelector
  );
  const prevIsInstancesCacheUpdating = usePrevious(isInstancesCacheUpdating);

  const instancesOverview = useSelector(instancesOverviewSelector);

  const queryParams = new URLSearchParams(location.search);

  const tableRegionsOptions = (
    appConfig.availableRegions
      ? [
          DEFAULT_REGION,
          ...appConfig.availableRegions.map((region) => ({
            name: region,
            id: region
          }))
        ]
      : []
  ).map((region) => getSelectOption(region, "name", "id"));

  const showOnlyRegionParam = queryParams.get("regions");
  const [showOnlyRegion, setShowOnlyRegion] = useState<string>(
    showOnlyRegionParam || tableRegionsOptions[0].value
  );
  const handleChangeRegionFilter = useCallback((value: string) => {
    setShowOnlyRegion(value);
    // setPage(0);
  }, []);

  const showOnlyStatusParam = queryParams.get("status");
  const [showOnlyStatus, setShowOnlyStatus] = useState<string>(
    showOnlyStatusParam?.toUpperCase() || VMS_STATUSES_FILTER_OPTIONS[0].value
  );
  const handleChangeStatusFilter = useCallback((value: string) => {
    setShowOnlyStatus(value);
    // setPage(0);
  }, []);

  const prevShowOnlyRegion = usePrevious(showOnlyRegion);
  const prevShowOnlyStatus = usePrevious(showOnlyStatus);

  const TAB_TITLES: { [key in TABS]: { title: string; disabled: boolean } } =
    useMemo(
      () => ({
        [TABS.OVERVIEW]: {
          title: "Overview",
          disabled: false
        },
        [TABS.ALL]: {
          title: "All Instances",
          disabled: false
        },
        [TABS.GPU]: {
          title: "GPU Instances",
          disabled: false
        }
      }),
      []
    );

  const activeTabIndexFromParam = Object.keys(TAB_TITLES).find(
    (key) =>
      TAB_TITLES[key] &&
      String(TAB_TITLES[key]).toLowerCase() ===
        new URLSearchParams(location.search).get("tab")
  );
  const [activeTabIndex, setActiveTabIndex] = useState(
    Number(activeTabIndexFromParam || TABS.OVERVIEW) as TABS
  );
  const previousActiveTabIndex = usePrevious(activeTabIndex);

  const handleChangeTab = useCallback((e, value: number) => {
    setActiveTabIndex(value);
  }, []);

  const handleConfirmUpdateCacheData = useCallback(() => {
    dispatch(instancesActions.updateCacheInstances.started());
    setIsRefreshButtonDisabled(true);
  }, [dispatch]);

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.allInstances}`,
        action: instancesActions.getInstancesCached.started({
          regions: showOnlyRegion,
          status: showOnlyStatus
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.gpuInstances}`,
        action: instancesActions.getGpuInstances.started({
          regions: showOnlyRegion,
          status: showOnlyStatus
        })
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(instancesActions.clear());
  });

  useEffect(() => {
    if (instancesCachedDate !== prevInstancesCachedDate) {
      setIsRefreshButtonDisabled(false);
    }
  }, [instancesCachedDate, prevInstancesCachedDate]);

  useEffect(() => {
    if (previousActiveTabIndex !== activeTabIndex) {
      setShowOnlyRegion(tableRegionsOptions[0].value);
      setShowOnlyStatus(VMS_STATUSES_FILTER_OPTIONS[0].value);

      // Cancel the previous requests due to new parameters
      cancelVmsTokens["listGpuVms"]?.cancel("Cancel the previous request");
      cancelVmsTokens["listGpuVms"] = null;
      cancelVmsTokens["listCachedVms"]?.cancel("Cancel the previous request");
      cancelVmsTokens["listCachedVms"] = null;

      // stop polling
      Object.values(POLL_IDS).forEach((id) => {
        dispatch(
          pollingActions.stopPolling({
            id: `${POLL_ID_PREFIX}/${id}`
          })
        );
      });

      // clear state - to add loaders if change some of the options
      dispatch(instancesActions.clearGpuInstancesList());
      dispatch(instancesActions.clearCachedInstancesList());

      switch (activeTabIndex) {
        case TABS.OVERVIEW:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.OVERVIEW].title, // tab: "all",
              regions: "all",
              status: "ALL"
            })
          });
          dispatch(
            instancesActions.getInstancesCached.started({
              regions: "all",
              status: "ALL"
            })
          );

          break;
        case TABS.ALL:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.ALL].title, // tab: "all",
              regions: String(showOnlyRegion),
              status: String(showOnlyStatus)
            })
          });
          dispatch(
            pollingActions.startPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.allInstances}`,
              action: instancesActions.getInstancesCached.started({
                regions: showOnlyRegion,
                status: showOnlyStatus
              })
            })
          );

          break;
        case TABS.GPU:
          history({
            search: generateSearchString({
              // filter: String(showOnlyGpu),
              tab: TAB_TITLES[TABS.GPU].title, // tab: "gpu",
              regions: String(showOnlyRegion),
              status: String(showOnlyStatus)
            })
          });

          dispatch(
            pollingActions.startPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.gpuInstances}`,
              action: instancesActions.getGpuInstances.started({
                regions: showOnlyRegion,
                status: showOnlyStatus
              })
            })
          );
          break;
      }
    }
  }, [
    previousActiveTabIndex,
    activeTabIndex,
    tableRegionsOptions,
    history,
    TAB_TITLES,
    showOnlyRegion,
    showOnlyStatus,
    prevShowOnlyRegion,
    prevShowOnlyStatus,
    dispatch
  ]);

  useEffect(() => {
    if (
      (prevShowOnlyRegion !== undefined &&
        prevShowOnlyRegion !== showOnlyRegion) ||
      (prevShowOnlyStatus !== undefined &&
        prevShowOnlyStatus !== showOnlyStatus)
    ) {
      switch (activeTabIndex) {
        case TABS.ALL:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.ALL].title, // tab: "all",
              regions: String(showOnlyRegion),
              status: String(showOnlyStatus)
            })
          });
          // Cancel the previous requests due to new parameters
          cancelVmsTokens["listCachedVms"]?.cancel(
            "Cancel the previous request"
          );
          cancelVmsTokens["listCachedVms"] = null;

          // stop polling
          dispatch(
            pollingActions.stopPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.allInstances}`
            })
          );

          // clear state - to add loaders if change some of the options
          dispatch(instancesActions.clearCachedInstancesList());

          dispatch(
            pollingActions.startPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.allInstances}`,
              action: instancesActions.getInstancesCached.started({
                regions: showOnlyRegion,
                status: showOnlyStatus
              })
            })
          );

          break;
        case TABS.GPU:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.GPU].title, // tab: "gpu",
              regions: String(showOnlyRegion),
              status: String(showOnlyStatus)
            })
          });
          // Cancel the previous requests due to new parameters
          cancelVmsTokens["listGpuVms"]?.cancel("Cancel the previous request");
          cancelVmsTokens["listGpuVms"] = null;

          // stop polling
          dispatch(
            pollingActions.stopPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.gpuInstances}`
            })
          );

          // clear state - to add loaders if change some of the options
          dispatch(instancesActions.clearGpuInstancesList());

          dispatch(
            pollingActions.startPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.gpuInstances}`,
              action: instancesActions.getGpuInstances.started({
                regions: showOnlyRegion,
                status: showOnlyStatus
              })
            })
          );
          break;
      }
    }
  }, [
    previousActiveTabIndex,
    activeTabIndex,
    tableRegionsOptions,
    history,
    TAB_TITLES,
    showOnlyRegion,
    showOnlyStatus,
    prevShowOnlyRegion,
    prevShowOnlyStatus,
    dispatch
  ]);

  const generateGpuTableItemURL = useCallback(
    (id: string) =>
      generatePath(ROUTES.USER, {
        userId: gpuInstancesTable?.find((vm) => vm.id == id)?.user_id
      }),
    [gpuInstancesTable]
  );

  const generateCachedTableItemURL = useCallback(
    (id: string) =>
      generatePath(ROUTES.USER, {
        userId: cashedInstancesTable?.find((vm) => vm.id == id)?.user_id
      }),
    [cashedInstancesTable]
  );

  const statusColors = {
    [INSTANCE_STATUSES.ACTIVE]: theme.palette.secondary.main,
    [INSTANCE_STATUSES.BUILD]: "#07C5C2",
    [INSTANCE_STATUSES.DELETED]: "#BDBDBD",
    [INSTANCE_STATUSES.ERROR]: "#E05C5C", //theme.palette.error.main,
    [INSTANCE_STATUSES.HARD_REBOOT]: "#FF6D00",
    [INSTANCE_STATUSES.MIGRATING]: "#4CAF50",
    [INSTANCE_STATUSES.PASSWORD]: "#FF8F00",
    [INSTANCE_STATUSES.PAUSED]: "#4A9DE0",
    [INSTANCE_STATUSES.REBOOT]: "#5778C2",
    [INSTANCE_STATUSES.REBUILD]: "#6A5CB2",
    [INSTANCE_STATUSES.RESCUE]: "#9A4FB8",
    [INSTANCE_STATUSES.RESIZE]: "#A565B5",
    [INSTANCE_STATUSES.REVERT_RESIZE]: "#D46F6A",
    [INSTANCE_STATUSES.SHELVED]: "#7D4EA5",
    [INSTANCE_STATUSES.SHELVED_OFFLOADED]: "#8047A5",
    [INSTANCE_STATUSES.SHUTOFF]: theme.palette.secondary.dark, // "#B35FA0",
    [INSTANCE_STATUSES.SOFT_DELETED]: "#9B5042",
    [INSTANCE_STATUSES.SUSPENDED]: "#FF5722",
    [INSTANCE_STATUSES.UNKNOWN]: "#9E9E9E",
    [INSTANCE_STATUSES.VERIFY_RESIZE]: theme.palette.warning.light
  };

  const flavorColors = {
    [INSTANCE_FLAVORS.VC_05]: "#4A9DE0",
    [INSTANCE_FLAVORS.VC_1]: "#5778C2",
    [INSTANCE_FLAVORS.VC_2]: "#6A5CB2",
    [INSTANCE_FLAVORS.VC_4]: "#8047A5",
    [INSTANCE_FLAVORS.VC_8]: "#B35FA0",
    [INSTANCE_FLAVORS.VC_16]: "#E05C5C",
    [INSTANCE_FLAVORS.VC_32]: "#E88555",
    [INSTANCE_FLAVORS.VC_64]: "#D46F6A",
    [INSTANCE_FLAVORS.VC_128]: "#A04874",
    [INSTANCE_FLAVORS.VC_225]: "#9B5042",
    [INSTANCE_FLAVORS.M1_AMPHORA]: theme.palette.secondary.main,
    [INSTANCE_FLAVORS.GPU_H100_1]: "#FF7043",
    [INSTANCE_FLAVORS.GPU_H100_2]: "#FF8A65",
    [INSTANCE_FLAVORS.GPU_H100_4]: "#FFAB91",
    [INSTANCE_FLAVORS.GPU_H100_8]: "#FFCCBC",
    [INSTANCE_FLAVORS.GPU_L40S_1]: "#8E24AA",
    [INSTANCE_FLAVORS.GPU_L40S_2]: "#BA68C8",
    [INSTANCE_FLAVORS.GPU_L40S_4]: "#CE93D8",
    [INSTANCE_FLAVORS.VC_32_x16]: "#5E35B1",
    [INSTANCE_FLAVORS.GPU_H100_4_fs]: "#9C27B0",
    [INSTANCE_FLAVORS.CPU_L40S_1_fs]: "#AB47BC",
    [INSTANCE_FLAVORS.VC_12_32_CARITAS_1]: "#C2185B",
    [INSTANCE_FLAVORS.VC_12_32_CARITAS_2]: "#D81B60",
    [INSTANCE_FLAVORS.C4V_16]: "#E57373",
    [INSTANCE_FLAVORS.C4V_32]: "#F06292",
    [INSTANCE_FLAVORS.VC_30x64]: "#9575CD",
    [INSTANCE_FLAVORS.VC_225_GPU_A100]: "#7986CB",
    [INSTANCE_FLAVORS.VC_64_GPU_A100]: "#64B5F6",
    [INSTANCE_FLAVORS.VC_16_GPU_A100]: "#4DD0E1",
    [INSTANCE_FLAVORS.VC_512]: "#81C784",
    [INSTANCE_FLAVORS.VC_24_GPU_A100]: "#AED581"
  };

  const renderMetric = (
    title: string,
    statusData: { [key: string]: number | undefined },
    total: number,
    unit: string = "",
    simpleMetrics: {
      label: string;
      value: number;
      unit: string;
      width?: string;
    }[],
    width?: number,
    showTotalString?: boolean,
    showUnitInLegend?: boolean,
    sortDescending?: boolean,
    maxItemsInLegend?: number,
    colorSource?: "status" | "flavor",
    lowerCaseLegend?: boolean,
    showPopoverOnOtherClick?: boolean
  ) => {
    const colorPalette = colorSource === "status" ? statusColors : flavorColors;

    const dataChart = Object.entries(statusData)
      .filter(([, count]) => count !== undefined)
      .map(([status, count]) => ({
        label: lowerCaseLegend ? status.toLowerCase() : status,
        value: count as number,
        color: colorPalette[status as keyof typeof colorPalette]
      }));

    return (
      <PieChartMetric
        title={title}
        dataChart={dataChart}
        total={total}
        unit={unit}
        simpleMetrics={simpleMetrics}
        width={width}
        showTotalString={showTotalString}
        showUnitInLegend={showUnitInLegend}
        sortDescending={sortDescending}
        maxItemsInLegend={maxItemsInLegend}
        showPopoverOnOtherClick={showPopoverOnOtherClick}
      />
    );
  };

  const renderSimpleMetric = (title, value, unit, helperText = "") => {
    return (
      // <s.MetricWrapper>
      <s.SimpleMetricsContainer>
        <Tooltip
          title={helperText}
          arrow
          placement={"top"}
          TransitionComponent={Zoom}
        >
          <s.SimpleMetricItem2>
            <s.SimpleMetricTitle>{title}</s.SimpleMetricTitle>
            <s.SimpleMetricValue>
              {value} {unit}
            </s.SimpleMetricValue>
          </s.SimpleMetricItem2>
        </Tooltip>
      </s.SimpleMetricsContainer>
      // </s.MetricWrapper>
    );
  };

  const tabContent = [
    <>
      <s.MetricsContainer variant={"outlined"}>
        {!instancesOverview ? (
          <Loader text={"Loading data..."} />
        ) : (
          <>
            {instancesOverview.map((data, index) => (
              <div key={index}>
                <s.TitleRegion component="h4">{data.region}</s.TitleRegion>
                <s.MetricRowContainer key={index}>
                  <s.MetricContainer>
                    {renderMetric(
                      "Statuses",
                      data.statistics_by_statuses || {},
                      data.total_servers_count,
                      "VMs",
                      [],
                      355, // width
                      false, // showTotalString
                      false, // showUnitInLegend
                      true, // sortDescending
                      7, // maxItemsInLegend
                      "status", // colorPalette source
                      true, //lowerCaseLegend
                      true //showPopoverOnOtherClick
                    )}
                  </s.MetricContainer>
                  <s.MetricContainer>
                    {renderSimpleMetric(
                      "Total VMs",
                      data.total_servers_count,
                      ""
                    )}
                    {renderSimpleMetric(
                      "Regular VMs",
                      data.other_servers_count,
                      ""
                    )}
                    {renderSimpleMetric("GPU VMs", data.gpu_servers_count, "")}
                  </s.MetricContainer>
                  <s.MetricContainer>
                    {renderMetric(
                      "Flavors",
                      data.statistics_by_flavors || {},
                      data.total_servers_count,
                      "vms",
                      [],
                      355, // width
                      false, // showTotalString
                      false, // showUnitInLegend
                      true, // sortDescending
                      7, // maxItemsInLegend
                      "flavor", // colorPalette source
                      false, //lowerCaseLegend
                      true //showPopoverOnOtherClick
                    )}
                  </s.MetricContainer>
                </s.MetricRowContainer>
                {/* {index !== instancesOverview.length - 1 && <s.StyledDivider />} */}
              </div>
            ))}
          </>
        )}
      </s.MetricsContainer>
    </>,
    <>
      <Table<TableVirtualMachine>
        isSearchEnabled={true}
        isSortingEnabled={true}
        rows={cashedInstancesTable || []}
        columns={tableColumns}
        isLoading={!cashedInstancesTable}
        itemLink={{
          column: "email",
          getURL: generateCachedTableItemURL
        }}
        toolbarItems={
          <>
            <Box style={{ display: "flex", flex: 1 }}>
              <CustomSelect
                label={"Region"}
                options={tableRegionsOptions}
                defaultValue={tableRegionsOptions[0].value}
                value={showOnlyRegion}
                onChange={handleChangeRegionFilter}
                margin={"0 15px 0 0"}
              />
              <CustomSelect
                label={"Status"}
                options={VMS_STATUSES_FILTER_OPTIONS}
                defaultValue={VMS_STATUSES_FILTER_OPTIONS[0].value}
                value={showOnlyStatus}
                onChange={handleChangeStatusFilter}
                margin={"0 25px 0 0"}
              />
            </Box>
            <s.CashContainer2>
              <s.ActionsContainer>
                {instancesCachedDate && (
                  <Tooltip title={"Report updated at:"} placement={"top"} arrow>
                    <span>
                      <s.Description>
                        Report date: {instancesCachedDate}
                      </s.Description>
                    </span>
                  </Tooltip>
                )}

                <Tooltip title={"Refresh report"} placement={"top"} arrow>
                  <span>
                    <IconButton
                      onClick={handleConfirmUpdateCacheData}
                      color={"inherit"}
                      disabled={
                        isRefreshButtonDisabled || !cashedInstancesTable
                      }
                    >
                      <s.RotatingRefreshIcon
                        $isSpinning={isRefreshButtonDisabled}
                      />
                      {/* <RefreshIcon /> */}
                    </IconButton>
                  </span>
                </Tooltip>
              </s.ActionsContainer>
            </s.CashContainer2>
          </>
        }
      />
    </>,
    <>
      <Table<TableVirtualMachine>
        isSearchEnabled={true}
        isSortingEnabled={true}
        rows={gpuInstancesTable || []}
        columns={tableColumns}
        isLoading={!gpuInstancesTable}
        itemLink={{
          column: "email",
          getURL: generateGpuTableItemURL
        }}
        toolbarItems={
          <>
            <CustomSelect
              label={"Region"}
              options={tableRegionsOptions}
              defaultValue={tableRegionsOptions[0].value}
              value={showOnlyRegion}
              onChange={handleChangeRegionFilter}
              margin={"0 15px 0 0"}
            />
            <CustomSelect
              label={"Status"}
              options={VMS_STATUSES_FILTER_OPTIONS}
              defaultValue={VMS_STATUSES_FILTER_OPTIONS[0].value}
              value={showOnlyStatus}
              onChange={handleChangeStatusFilter}
              margin={"0 25px 0 0"}
            />
          </>
        }
      />
    </>,
    <></>
  ];

  return (
    <>
      <Head title={title} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <s.SummaryContainer>
        <s.Title variant={"h4"} component={"h2"}>
          {title}
        </s.Title>
      </s.SummaryContainer>
      <s.TabsContainer>
        <Tabs value={activeTabIndex} onChange={handleChangeTab}>
          {Object.values(TAB_TITLES).map((tab) => (
            <Tab key={tab.title} label={tab.title} disabled={tab.disabled} />
          ))}
        </Tabs>
      </s.TabsContainer>
      {tabContent[activeTabIndex]}
    </>
  );
};
